/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Button, FormControl} from 'react-bootstrap'
import {toast} from 'react-hot-toast'
import {useCopyToClipboard} from 'usehooks-ts'
import {frontUrl, sendRequest} from '../../../../Helpers/Requests'

type Props = {
  className: string
  config: any
}

const IndicationLink: React.FC<Props> = ({className, config}) => {
  const [value, copy] = useCopyToClipboard()
  const [link, setLink] = useState('')
  const [perna, setPerna] = useState('')

  const handleCopyLink = () => {
    copy(link)
    toast.success('link copiado')
  }

  const handleGetLink = async () => {
    const req = await sendRequest('usuario/url', 'GET')

    if (req.tipo === 'success') {
      let endpoint = 'auth/registration/'

      setLink(frontUrl(endpoint + req.url.url))
      setPerna(req.url.perna)
    }
  }

  const changePerna = async (perna: string) => {
    const req = await sendRequest(`usuario/url`, 'POST', {
      perna,
    })

    const response = await req

    if (response.tipo === 'success') {
      let endpoint = 'auth/registration/'

      setLink(frontUrl(endpoint + req.url.url))
      setPerna(req.url.perna)

      toast.success('Link Alterado com sucesso!')
    } else {
      toast.error('Não foi possível alterar o link.')
    }
  }

  const togglePerna = async (perna: string) => {
    changePerna(perna)
  }

  useEffect(() => {
    handleGetLink()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Link de indicação</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Este é seu link para cadastrar membros em sua rede.
          </span>
        </h3>
        {/* <div className='card-toolbar'></div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='row'>
          <div className='col-lg-10'>
            <FormControl readOnly={true} value={link} />
          </div>
          <div className='col-lg-2'>
            <div className='d-grid gap-2'>
              <button
                onClick={handleCopyLink}
                className='btn btn-success btn-icon-success btn-text-default'
                data-kt-indicator={link ? "off" : "on"}
              >
                <span className='indicator-label'>Copiar Link</span>
                <span className='indicator-progress'>
                  Aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        {config.binario && (
          <div className='row mt-5'>
            <div className='col-lg-6'>
              <button
                onClick={() => togglePerna('esquerda')}
                style={{width: '100%', display: 'flex', justifyContent: 'center'}}
                className={`btn btn-flex ${
                  perna == 'esquerda' ? 'btn-primary' : 'btn-light-primary'
                } px-6`}
              >
                Esquerda
              </button>
            </div>
            <div className='col-lg-6'>
              <button
                onClick={() => togglePerna('direita')}
                style={{width: '100%', display: 'flex', justifyContent: 'center'}}
                className={`btn btn-flex ${
                  perna == 'direita' ? 'btn-primary' : 'btn-light-primary'
                } px-6`}
              >
                Direita
              </button>
            </div>
          </div>
        )}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {IndicationLink}
