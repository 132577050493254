export const defaultValues = {
    empresa: "",
    logo: "",
    favicon: "",
    needsToLoad: true,
    login: {
        background: null,
        backgroundColor: "#006CEA",
        title: "Seja bem vindo(a)",
        text: "",
        footer: "",
      },
    moeda: {
      converter: false,
      prefixo: '$'
    },
    banners:{}
};