import { useEffect, useState, useContext } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { sendRequest } from "../../../Helpers/Requests"
import { PageTitle } from "../../../_metronic/layout/core"
import { MixedWidget2, MixedWidget3 } from "../../../_metronic/partials/widgets"
import { MixedCustomToWithdraw } from "../../../_metronic/partials/widgets/mixed/MixedCustomToWithdraw"
import ConfigContext from "../../contexts/Config/ConfigContext"

const Bonificacoes = () => {
    const navigate = useNavigate()

    const [on, setOn] = useState<boolean>(false)
    const [balance, setBalance] = useState<any>(0)
    const [withdraw, setWithdraw] = useState<any>(false)
    const [tax, setTax] = useState<any>(0)
    const [lastWithdraw, setLastWithdraw] = useState<any>(null)
    const [minWithdraw, setMinWithdraw] = useState<any>(0)
    const [ wallets, setWallets ] = useState<any>(null)
    const [ methods, setMethods ] = useState<any>(null)

    const config = useContext(ConfigContext)

    const handleHabilitado = async () => {
        const req = await sendRequest(`saques/habilitado`, 'POST', {
            metodo: 'wallet',
            tipo: 'bonus'
        })
        const response = await req

        if (response.tipo === 'success') {
            const { saldo, saque, taxa, ultimo_saque, valor_minimo, carteiras, metodos } = response

            setOn(true)
            setBalance(saldo)
            setWithdraw(saque)
            setTax(taxa)
            setLastWithdraw(ultimo_saque)
            setMinWithdraw(valor_minimo)
            setWallets(carteiras)
            setMethods(metodos)

        } else {
            navigate('/meus-saques')        
        }
    }

    useEffect(() => {
        handleHabilitado()
    }, [])

    return (
        <>
            {on &&
                <>
                    <PageTitle breadcrumbs={[]}>Solicitar saque - Bonificações</PageTitle>
                    <div className="row">
                        <div className="col-lg-6">
                            <MixedCustomToWithdraw title="Bonificações" className="" color="info" balance={balance} tax={tax} lastWithdraw={lastWithdraw} minWithdraw={minWithdraw} methods={methods} wallets={wallets} type="bonus" />
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                                <div className="col-sm-12">
                                    <div className="card card-flush">
                                        <div className="card-body d-flex align-items-end pt-5">
                                            <div className="row">

                                                <h3>Regras do saque:</h3>
                                                <div className="alert alert-warning mt-5" style={{ width: '100%' }}>
                                                <strong>Saque Mínimo: {config.moeda.prefixo} {parseFloat(minWithdraw).toFixed(2)}</strong><br /><br />

                                                <strong>Pagamentos rendimentos executados até o quinto dia util </strong><br /><br />

                                                <strong>Pagamentos bonificações efetuados em até 72 horas</strong><br /><br />

                                                <strong>Será necessário confirmar o pedido de saque via email para que o mesmo seja concluído.</strong><br /><br />

                                                <strong>Após a confirmação do pagamento por e-mail, pode levar até 24 horas para que seja concluido.</strong><br /><br />

                                                <strong>Gerar um novo pedido de saque resultará na remoção de pedidos anteriores que estejam aguardando confirmação via email.
                                                </strong><br /><br />

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export { Bonificacoes }