import validadorCPF from './validadorCpf'
import validadorEmail from './validadorEmail'
import validadorData from './validadorData'

function required(text) {
  return text.length > 0
}

function requiredValidation(input) {
  var text = input.value;
  if (!required(text)) return {valido: false, texto: 'Este campo é obrigatório.'}

  return {valido: true, texto: ''}
}

function cpfValidation(input) {
  var text = input.value;
  if (!required(text)) return {valido: false, texto: 'Este campo é obrigatório.'}

  if (!validadorCPF(text)) return {valido: false, texto: 'CPF Inválido.'}

  return {valido: true, texto: ''}
}

function emailValidation(input) {
  var text = input.value;
  if (!required(text)) return {valido: false, texto: 'Este campo é obrigatório.'}

  if (!validadorEmail(text)) return {valido: false, texto: 'Email Inválido.'}

  return {valido: true, texto: ''}
}

function passwordValidation(input) {
  var text = input.value;
  if (!required(text)) return {valido: false, texto: 'Este campo é obrigatório.'}

  if (text.length < 4 || text.length > 40)
    return {valido: false, texto: 'Sua senha deve ter entre 4 e 72 dígitos.'}

  let validadorTexto = text.replace(/\s/g, '')
  if (text !== validadorTexto) return {valido: false, texto: 'Sua senha não pode conter espaços.'}

  return {valido: true, texto: ''}
}

function passwordConfirmationValidation(input) {
  var text = input.value;
  if (!required(text)) return {valido: false, texto: 'Este campo é obrigatório.'}

  const retorno = passwordValidation(input)

  if (!retorno.valido) return retorno

  if (document.querySelector(input.confirm).value !== text)
    return {valido: false, texto: 'As senhas não conferem.'}

  return {valido: true, texto: ''}
}

function phoneValidation(input) {
  var text = input.value;
  text = text.replace(/\D/g, '')
  if (text.length < 10) return {valido: false, texto: 'Número de celular inválido.'}

  return {valido: true, texto: ''}
}

function cepValidation(input) {
  var text = input.value;
  text = text.replace(/\D/g, '')
  if (text.length < 8) return {valido: false, texto: 'Seu cep deve ter 8 números.'}

  return {valido: true, texto: ''}
}

function nameValidation(input) {
  var text = input.value;
  if (!required(text)) return {valido: false, texto: 'Este campo é obrigatório.'}

  let validadorTexto = text.replace(/[^a-zA-Z\s]/g, '')

  if (text !== validadorTexto)
    return {
      valido: false,
      texto: 'O nome só pode conter letras.',
    }

  return {valido: true, texto: ''}
}

function usernameValidation(input) {
  var text = input.value;
  if (!required(text)) return {valido: false, texto: 'Este campo é obrigatório.'}

  let validadorTexto = text.replace(/\s/g, '')
  validadorTexto = validadorTexto.replace(/[^a-z0-9]/g, '')
  validadorTexto = validadorTexto.toLowerCase()

  if (text !== validadorTexto)
    return {
      valido: false,
      texto: 'O usuário não pode conter letras maiúsculas, caracteres especiais nem espaços.',
    }

  return {valido: true, texto: ''}
}

function usernameNotRequiredValidation(input) {
  var text = input.value;
  let validadorTexto = text.replace(/\s/g, '')
  validadorTexto = validadorTexto.replace(/[^a-z0-9]/g, '')
  validadorTexto = validadorTexto.toLowerCase()

  if (text !== validadorTexto)
    return {
      valido: false,
      texto: 'O usuário não pode conter letras maiúsculas, caracteres especiais nem espaços.',
    }

  return {valido: true, texto: ''}
}

function dateValidation(input) {
  var text = input.value;
  let textLength = text.replace(/\D/g, '').length
  if (textLength < 8) return {valido: false, texto: 'Sua data deve ter o formato dd/mm/YYYY.'}

  if (!validadorData(text)) return {valido: false, texto: 'Data inválida.'}

  return {valido: true, texto: ''}
}

function checkboxRequired(input){
  let value = input.value

  if (!value) {
    return {
      valido: false,
      texto: 'Esse campo é obrigatório',
    }
  }

  return {valido: true, texto: ''}
}

function imageValidation(input, args) {
  /*
  TO-DO
  const {callback, key, selector} = args
  if (files && files.length > 0) {
    for (let i = 0; i < files.length; i++) {
      var reader = new FileReader()
      reader.onload = function (e) {
        callback({[key]: e.target.result})
        document.querySelector(selector).innerText = files[i].name
      }

      const file = files[i]
      const fileType = file['type']
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']

      if (validImageTypes.includes(fileType)) {
        reader.readAsDataURL(files.item(i))
        return {valido: true, texto: ''}
      }
    }
  }

  return {valido: false, texto: 'Formato de arquivo inválido!'} */
  return {valido: true, texto: ''}
}

export {
  requiredValidation,
  cpfValidation,
  passwordValidation,
  emailValidation,
  cepValidation,
  dateValidation,
  phoneValidation,
  usernameValidation,
  passwordConfirmationValidation,
  nameValidation,
  usernameNotRequiredValidation,
  imageValidation,
  checkboxRequired,
}
