import { toAbsoluteUrl } from "../../../../_metronic/helpers"

const renderAvatar = (avatarUrl, genero) => {
  let img = avatarUrl

  if (avatarUrl === null) {
    if (genero === 'F') {
      img = toAbsoluteUrl('/media/avatars/300-14.jpg')
    } else if (genero === 'M') {
      img = toAbsoluteUrl('/media/avatars/300-1.jpg')
    }
  }
  return <img src={img} alt='' />
}

export const rowModel = (row) => {
  return [
    {
      value: `#${row.id}`,
      translatable: false,
    },
    {
      render: () => (
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-45px me-5'>{renderAvatar(row.imagem, row.sexo)}</div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 notranslate'>
              {row.nome}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7 notranslate'>
              {row.login}
            </span>
          </div>
        </div>
      ),
    },
    {
      render: () => (
        <>
          {row.ativo == 1 && row.bloqueado == 0 && <div className='badge badge-success'>Ativo</div>}

          {row.ativo == 0 && row.bloqueado == 1 && (
            <div className='badge badge-danger'>Inativo</div>
          )}
        </>
      ),
    },
    {
      value: row.indicator.login,
      translatable: false,
    },
    {
      render: () => <div className='badge badge-info'>{row.perna}</div>,
    },
  ]
}
