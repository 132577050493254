import clsx from 'clsx'
import {
  emailValidation,
  nameValidation,
  passwordConfirmationValidation,
  passwordValidation,
  phoneValidation,
  requiredValidation,
  usernameValidation,
  cpfValidation,
  checkboxRequired
} from '../app/modules/form/validations/validations'
import {phoneMask, cpfMask} from '../app/modules/form/masks/masks'
import {countries} from './countries'

const getCountryOptions = () => {
  let options = []
  countries.forEach((country) => {
    options.push({
      label: country,
      value: country,
    })
  })
  return options
}

const getLicenca = (licenca) => {
  if (!licenca) return []

  return [
    {
      type: 'element',
      className: 'alert alert-warning',
      children: (
        <>Será cobrada uma taxa de ${licenca.toFixed(2)} para licenciar seu cadastro!</>
      ),
    },
  ]
}

export default function getRegistrationInputs(licenca) {
  const appends = getLicenca(licenca);
  return [
    {
      name: 'nome',
      label: 'Nome Completo',
      className: 'form-control bg-transparent',
      placeholder: 'Seu nome Completo',
      type: 'text',
      autoComplete: 'off',
      className: clsx('form-control bg-transparent'),
      containerAttributes: {
        className: 'fv-row mb-2 col-12 col-md-12',
      },
      labelAttributes: {
        className: 'form-label fw-bolder text-dark fs-6',
      },
      validation: nameValidation,
    },
    {
      name: 'celular',
      label: 'Celular',
      className: 'form-control bg-transparent',
      placeholder: '(99) 9999-9999',
      type: 'text',
      autoComplete: 'off',
      className: clsx('form-control bg-transparent 2'),
      containerAttributes: {
        className: 'fv-row mb-2  col-12 col-md-6',
      },
      labelAttributes: {
        className: 'form-label fw-bolder text-dark fs-6',
      },
      validation: phoneValidation,
      mask: null,
    },
    {
      name: 'cpf',
      label: 'CPF',
      className: 'form-control bg-transparent',
      placeholder: '123.456.789-10',
      type: 'text',
      autoComplete: 'off',
      className: clsx('form-control bg-transparent 2'),
      containerAttributes: {
        className: 'fv-row mb-2  col-12 col-md-6',
      },
      labelAttributes: {
        className: 'form-label fw-bolder text-dark fs-6',
      },
      validation: cpfValidation,
      mask: cpfMask,
    },
    {
      name: 'pais',
      label: 'País',
      className: 'form-select select-dark bg-transparent',
      type: 'select',
      options: getCountryOptions(),
      value: 'Brasil',
      autoComplete: 'off',
      containerAttributes: {
        className: 'fv-row mb-2 col-12 col-md-6',
      },
      labelAttributes: {
        className: 'form-label fw-bolder text-dark fs-6',
      },
      validation: requiredValidation,
    },
    {
      name: 'sexo',
      label: 'Sexo',
      className: 'form-select select-dark bg-transparent',
      type: 'select',
      options: [
        {
          label: 'Masculino',
          value: 'M',
        },
        {
          label: 'Feminino',
          value: 'F',
        },
      ],
      value: 'M',
      autoComplete: 'off',
      containerAttributes: {
        className: 'fv-row mb-2 col-12 col-md-6',
      },
      labelAttributes: {
        className: 'form-label fw-bolder text-dark fs-6',
      },
      validation: requiredValidation,
    },
    {
      name: 'email',
      label: 'Email',
      placeholder: 'seu-email@provedor.com',
      className: 'form-control bg-transparent',
      type: 'text',
      autoComplete: 'off',
      className: clsx('form-control bg-transparent'),
      containerAttributes: {
        className: 'fv-row mb-2 col-12 col-md-6',
      },
      labelAttributes: {
        className: 'form-label fw-bolder text-dark fs-6',
      },
      validation: emailValidation,
    },
    {
      name: 'login',
      label: 'Usuário',
      placeholder: 'seu-usuario',
      className: 'form-control bg-transparent',
      type: 'text',
      autoComplete: 'off',
      className: clsx('form-control bg-transparent'),
      containerAttributes: {
        className: 'fv-row mb-2 col-12 col-md-6',
      },
      labelAttributes: {
        className: 'form-label fw-bolder text-dark fs-6',
      },
      validation: usernameValidation,
    },
    {
      name: 'senha',
      label: 'Senha',
      type: 'password',
      id: 'passwordForm',
      placeholder: '*********',
      className: 'form-control bg-transparent',
      autoComplete: 'off',
      className: clsx('form-control bg-transparent'),
      containerAttributes: {
        className: 'fv-row mb-2  col-12 col-md-6',
      },
      labelAttributes: {
        className: 'form-label fw-bolder text-dark fs-6',
      },
      validation: passwordValidation,
    },
    {
      name: 'senha_confirmation',
      label: 'Confirme a senha',
      type: 'password',
      id: 'passwordFormConfirmation',
      confirm: '#passwordForm',
      placeholder: '*********',
      className: 'form-control bg-transparent',
      autoComplete: 'off',
      className: clsx('form-control bg-transparent'),
      containerAttributes: {
        className: 'fv-row mb-2  col-12 col-md-6',
      },
      labelAttributes: {
        className: 'form-label fw-bolder text-dark fs-6',
      },
      validation: passwordConfirmationValidation,
    },
    {
      name: 'terms_accept',
      label: <span className='px-2'> Eu li e concordo com os <a href="https://docs.google.com/document/d/1JSxO5SaVvb0hddVuTiLEBf_Xw59p6S0j/edit?usp=sharing&ouid=104560017965696224770&rtpof=true&sd=true" target="_blank"> Termos de uso</a></span>,
      type: 'checkbox',
      value: false,
      // className: clsx('bg-transparent'),
      containerAttributes: {
        className: 'mt-2',
      },
      // labelAttributes: {
      //   className: 'form-label fw-bolder text-dark fs-6',
      // },
      validation: checkboxRequired
    },
    ...appends
  ]
}
